<template>
  <div id="UserComponent">
    <el-row>
      <el-col :span="5" >
        <div style="padding: 10px;margin: 10px;background-color: #FFFFFF;">
          <el-input
              prefix-icon="el-icon-search"
              placeholder="输入关键字进行过滤"
              v-model="filterText"
              clearable>
          </el-input>
          <el-tree
              style="padding-top: 20px;height:calc(100vh - 293px);overflow: auto"
              class="filter-tree"
              :data="treedata"
              :props="defaultProps"
              default-expand-all
              :filter-node-method="filterNode"
              @node-click="click"
              ref="tree">
          </el-tree>
        </div>
      </el-col>
      <el-col :span="19">
        <div style="margin: 10px;background-color: #FFFFFF">
          <div style="padding:10px;font-size: 14px;font-weight: bolder">
            状态：
            <el-select clearable  size="mini" style="width:130px;" class="EquipmentMarginLeft" v-model="pageParams.generalStatus" placeholder="用户状态">
              <el-option
                  v-for="item in userStatus"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select>
            姓名：
            <el-input size="mini" v-model="pageParams.realname" style="width:130px;" placeholder="用户姓名" class="EquipmentMarginLeft"></el-input>
            手机号码：
            <el-input size="mini" v-model="pageParams.mobile" style="width:130px;" placeholder="用户手机号码" class="EquipmentMarginLeft"></el-input>
            创建日期：<el-date-picker
              v-model="DataPicker"
              size="mini"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              value-format="yyyy-MM-dd"
              end-placeholder="结束日期">
          </el-date-picker>
            <el-button size="mini" type="primary" style="margin-left:20px;" icon="el-icon-search" @click="search">查询</el-button>
            <el-button type="primary" size="mini" icon="el-icon-refresh" @click="recet">重置</el-button>
            <el-button type="success" size="mini" icon="el-icon-plus" @click="addUser">添加用户</el-button>
          </div>
          <el-table :data="userList" stripe
                    style="margin-top: 20px"
                    class="table-height-in"
                    :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9'}">
            <el-table-column type="index"  label="编号" align="center" width="80"></el-table-column>
            <el-table-column align="center" prop="username" label="用户名" sortable></el-table-column>
            <el-table-column align="center" prop="realname" label="用户姓名" sortable></el-table-column>
            <el-table-column align="center" prop="deptName" label="部门" sortable></el-table-column>
            <el-table-column align="center" prop="mobile" label="手机号码" sortable></el-table-column>
            <el-table-column align="center" prop="generalStatus" label="状态" sortable>
              <template slot-scope="{row}" >
                <el-switch v-model="row.generalStatus" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" @change="statusChange($event, row)"></el-switch>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="createTime" label="创建时间" sortable></el-table-column>
            <el-table-column align="left" prop="State" label="操作">
              <template slot-scope="{row}">
                <el-button type="text" @click="updataUser(row)" size="small">编辑</el-button>
                <el-button type="text" @click="del(row)" size="small" style="color:red">删除</el-button>
                <el-button type="text" @click="resetPassword(row)" size="small" style="color:#67C23A">密码重置</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: center;padding: 20px 0">
            <el-pagination
                @size-change="handleSizeChange"
                style="margin-top:10px;"
                background
                layout="sizes,prev, pager, next,jumper"
                :total="total"
                :page-sizes="[10,30,50,100]"
                :page-size="pageParams.size"
                :current-page="pageParams.current"
                @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog
        :title="updataStatus==0?'添加用户信息':'编辑用户信息'"
        :visible.sync="dialogVisible"
        width="35%"
        :close-on-click-modal="false"
    @close="clearnF('form')">
      <el-form ref="form" :model="form" label-width="100px" :rules="rule">
        <div style="display: flex">
          <el-form-item label="用户名：" prop="username" v-if="updataStatus==0">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password" v-if="updataStatus==0">
            <el-input v-model="form.password" type="password"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="姓名：" prop="realname">
            <el-input v-model="form.realname"></el-input>
          </el-form-item>
          <el-form-item label="性别：">
            <el-select clearable  v-model="form.sex" placeholder="请选择性别" style="width: 202px">
              <el-option label="男" value="0"></el-option>
              <el-option label="女" value="1"></el-option>
              <el-option label="未知" value="2"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="手机号码：" prop="mobile">
            <el-input v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="邮箱：">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="所属部门：" prop="deptId">
            <el-cascader
                v-model="form.deptId"
                :options="treedata"
                :props="TreeProps"
                :show-all-levels="true"
                
                ref="cascaderHandle"
                @change="close"
            >
              <span slot-scope="{data}" @click="clickNode">
                  {{data.name}}
              </span>
            </el-cascader>
          </el-form-item>
          <div></div>
          <el-form-item label="用户状态：">
            <el-radio-group v-model="form.generalStatus">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item label="选择角色：" prop="roleIds">
          <el-select clearable multiple  filterable v-model="form.roleIds" placeholder="请选择角色" style="width: 100%">
            <el-option v-for="(item,index) in roleArray_group" :key="index" :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择岗位：" prop="postIds">
          <el-select clearable   multiple filterable v-model="form.postIds" placeholder="请选择岗位" style="width: 100%">
            <el-option v-for="(item,index) in postIds_group" :key="index" :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button v-if="updataStatus==0" type="primary" @click="submitfrom('form')">确 定</el-button>
    <el-button v-else type="primary" @click="updatasubmitfrom('form')">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectSysDeptTreeList,
  selectUserDeptPageList,
  saveUser,
  updateUser,
  delUser,
  updateUserResetPassword,
  selectPostList, inspectorDelcheck
} from '../../RequestPort/user/user'
import {selectRoleList} from "../../RequestPort/user/role";
import {selectLocalSetting} from "../../RequestPort/management/management";
export default {
  name: "user",
  data() {
    var checkduoxuan = (rule, value, callback) => {
      if (this.form.roleIds.length==0) {
        callback(new Error('请选择角色'))
      } else {
        callback()
      }

    };
    var checkpost = (rule, value, callback) => {
      if (this.form.postIds.length==0) {
        callback(new Error('请选择岗位'))
      } else {
        callback()
      }

    };
    return {
      TreeProps:{
        label: 'name',
        value:'id',
        children:'children',
        checkStrictly: true
      },
      departmentId:'',
      pageParams:{
        userId:localStorage.getItem('userId'),
        current:1,
        size: 100,
        mobile:'',
        startTime:'',
        endTime:'',
        username:'',
        deptId:'',
        generalStatus:''
      },
      form:{
        generalStatus:1,
        sex:'0',
        roleIds:[]
      },
      updataStatus:0,
      dialogVisible:false,
      total:0,
      userList:[],
      postIds_group:[],
      userStatus:[{label:'启用',id:'1'},{label:'禁用',id:'0'}],
      roleArray_group:[],
      filterText: '',
      treedata: [],
      DataPicker:[],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      rule:{
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        deptId: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        roleIds: [
          { required: true, message: '请选择角色', trigger: 'blur',validator: checkduoxuan }
        ],
        postIds: [
          { required: true, message: '请选择岗位', trigger: 'blur',validator: checkpost }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6,  message: '长度在最少为6个字符', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
      },
      companyIds:''
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted() {
    selectSysDeptTreeList({deptListShow:'n'}).then((e)=>{
      let tree=e.data
      // if(tree[0]){
      //   tree[0].disabled=true
      // }
      // // 特殊处理
      // if(tree[0].children) {
      //   tree[0].children.forEach(el => {
      //     if(el.id == 8) {
      //       el.disabled=true
      //     }
      //   });
      // }
      // tree.forEach(el => {
      //     if(el.code == "hfrq001") {
      //       el.disabled=true
      //     }
      //   });
      this.treedata = tree
    })
    selectRoleList().then((res)=>{
      this.roleArray_group = res.data
    })
    selectPostList().then(res=>{
      this.postIds_group = res.data
    })
    this.loadList(this.pageParams)
  },
  methods: {
    close(val){
      console.log(val,"val")
      if(val){
        this.form.companyId = val[0]
      }
      this.$refs.cascaderHandle.dropDownVisible = false; //监听值发生变化就关闭它
    },
    clickNode($event){
      $event.target.parentElement.parentElement.firstElementChild.click();
    },
    resetPassword(row){
      this.$confirm(`是否重置此用户密码?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        // selectLocalSetting().then(e=>{
          // row.password = e.data[0].initpassword
          row.password = 123456
          updateUserResetPassword(row)
          this.$message.success('重置成功')
        // })
      })
    },
    clearnF(form){
      this.form = {
        generalStatus:1,
        sex:'0'
      }
      this.$refs[form].resetFields()
    },
    del(row){
      this.$confirm(`是否删除账号---[ ${row.realname} ]?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        inspectorDelcheck({inspectorId:row.userId}).then(res=>{
          if(res.code===200){
            delUser({userId:row.userId}).then((e)=>{
              if(e.code===200){
                this.$message.success(e.msg)
                this.loadList(this.pageParams)
              }else{
                this.$message.error(e.msg)
              }
            })
          }else {
            this.$message.error(e.msg)
          }
        })

      })
    },
    updataUser(row){
      this.updataStatus = 1
      this.form = JSON.parse(JSON.stringify(row))
      this.form.sex  =row.sex+''
      if(this.form.roleIds){
        this.form.roleIds = this.form.roleIds.split(",")
      }
      if(this.form.postIds){
        this.form.postIds = this.form.postIds.split(",")
      }
      this.dialogVisible = true
    },
    submitfrom(form) {
      var myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      var reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if(this.form.email){
        if(!reg.test(this.form.email)){
          return this.$message.error('请输入有效的邮箱')
        }
      }
      if (!myreg.test(this.form.mobile)) {
        return this.$message.error('手机号格式错误')
      } else {
        this.$refs[form].validate((valid) => {
          if(!valid) return false
          let ajaxData = {}
          let departmentId = this.form.deptId[this.form.deptId.length-1]
          // this.form.companyId = this.form.deptId[0]
          this.form.companyId = this.form.companyId ? this.form.companyId : this.companyIds
          ajaxData = JSON.parse(JSON.stringify(this.form))
          ajaxData.deptId = departmentId?departmentId:this.departmentId
          //ajaxData.roleIds = [this.form.roleIds]
          
          saveUser(ajaxData).then((e)=>{
            if(e.code===200){
              this.$message.success(e.msg)
              this.dialogVisible = false
              this.loadList(this.pageParams)
            }else {
              this.$message.error(e.msg)
            }
          })
        })
      }
    },
    updatasubmitfrom(form){
      var myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      var reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if(this.form.email){
        if(!reg.test(this.form.email)){
          return this.$message.error('请输入有效的邮箱')
        }
      }
      if (!myreg.test(this.form.mobile)) {
        return this.$message.error('手机号格式错误')
      } else {
        this.$refs[form].validate((valid) => {
          if(!valid) return false
          let ajaxData = {}
          let departmentId = this.form.deptId[this.form.deptId.length-1]
          // this.form.companyId = this.form.deptId[0]
          this.form.companyId = this.form.companyId ? this.form.companyId : this.companyIds
          ajaxData = JSON.parse(JSON.stringify(this.form))
          ajaxData.deptId = typeof(this.form.deptId)=='number' ? this.form.deptId:this.form.deptId[this.form.deptId.length-1]
          // ajaxData.deptId = departmentId?departmentId:this.departmentId
         // ajaxData.roleIds = [this.form.roleIds]
          updateUser(ajaxData).then((e)=>{
            if(e.code===200){
              this.$message.success(e.msg)
              this.dialogVisible = false
              this.loadList(this.pageParams)
            }else {
              this.$message.error(e.msg)
            }
          })
        })
      }
    },
    addUser(){
      this.updataStatus = 0
      this.dialogVisible = !this.dialogVisible
      this.form.deptId = this.departmentId!=''?this.departmentId:''
    },
    loadList(obj){
      selectUserDeptPageList(obj).then((e)=>{
        this.userList = e.data.records
        this.total = e.data.total
      })
    },
    recet(){
      this.pageParams.realname = ''
      this.pageParams.current=1
      this.pageParams.username=''
      this.pageParams.endTime=''
      this.pageParams.startTime=''
      this.pageParams.username=''
      this.pageParams.generalStatus=''
      this.pageParams.deptId=''
      this.pageParams.size=10
      this.pageParams.mobile=''
      this.DataPicker = []
      this.loadList(this.pageParams)
    },
    search(){
      if(this.DataPicker.length>0){
        this.pageParams.startTime  =this.DataPicker[0]
        this.pageParams.endTime  =this.DataPicker[1]
      }
      this.pageParams.current = 1
      this.loadList(this.pageParams)
    },
    currentChange(current){
      this.pageParams.current = current
      this.loadList(this.pageParams)
    },
    handleSizeChange(val){
      this.pageParams.size = val
      this.loadList(this.pageParams)
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    click(obj){
      console.log(obj,'obj')
      this.pageParams.deptId = obj.id
      this.departmentId = obj.id
      this.form.deptId = obj.id
      this.companyIds = obj.companyId
      this.pageParams.current = 1
      this.loadList(this.pageParams)
    },
    statusChange(val, row) {
      let obj = JSON.parse(JSON.stringify(row))
      if(row.roleIds){
        obj.roleIds = obj.roleIds.split(",")
      }
      if(obj.postIds){
        obj.postIds = obj.postIds.split(",")
      }
      const statusText = val === 0 ? '禁用' : '开启'
      inspectorDelcheck({inspectorId:row.userId}).then(res=> {
        if (res.code === 200) {
          this.$confirm(`是否${ statusText }此用户账号?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal:false,
            type: 'warning'
          }).then(() => {
            row.generalStatus = val
            updateUser(obj)
          }).catch(() => {
            row.generalStatus ==0?row.generalStatus=1:row.generalStatus =0
          })
        }else {
          this.$message.error(statusText+'失败')
          row.generalStatus ==0?row.generalStatus=1:row.generalStatus =0
        }

      })

    },
  },
}
</script>

<style scoped>

</style>
