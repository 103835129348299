import axios from '@/common/js/request'

export function selectSysDeptTreeList(params){
    const selectSysDeptTreeList = axios({
        url: '/user/selectSysDeptTreeList',
        method: 'get',
        params
    })
    return selectSysDeptTreeList
}
export function selectUserDeptPageList(params){
    const selectUserDeptPageList = axios({
        url: '/user/selectUserDeptPageList',
        method: 'get',
        params
    })
    return selectUserDeptPageList
}
export function saveUser(data){
    const saveUser = axios({
        url: '/user/saveUser',
        method: 'post',
        data
    })
    return saveUser
}
export function updateUser(data){
    const updateUser = axios({
        url: '/user/updateUser',
        method: 'post',
        data
    })
    return updateUser
}

export function saveDepart(data){
    const saveDepart = axios({
        url: '/dept/save',
        method: 'post',
        data
    })
    return saveDepart
}
export function updateDepart(data){
    const updateDepart = axios({
        url: '/dept/update',
        method: 'post',
        data
    })
    return updateDepart
}
export function delUser(params){
    const delUser = axios({
        url: '/user/deleteUser',
        method: 'get',
        params
    })
    return delUser
}
export function inspectorDelcheck(params){
    const inspectorDelcheck = axios({
        url: '/aaumTask/inspectorDelcheck',
        method: 'get',
        params
    })
    return inspectorDelcheck
}
export function delDepart(params){
    const delDepart = axios({
        url: '/dept/delete',
        method: 'get',
        params
    })
    return delDepart
}
export function selectPostList(params){
    const selectPostList = axios({
        url: '/post/selectPostList',
        method: 'get',
        params
    })
    return selectPostList
}
//部门筛选电接口
export function selectDepart(data){
    const selectDepart = axios({
        url: '/dept/page',
        method: 'post',
        data
    })
    return selectDepart
}
export function updateUserPassword(data){
    const updateUserPassword = axios({
        url: '/user/updateUserPassword',
        method: 'post',
        data
    })
    return updateUserPassword
}
export function updateUserResetPassword(data){
    const updateUserResetPassword = axios({
        url: '/user/updateUserResetPassword',
        method: 'post',
        data
    })
    return updateUserResetPassword
}
