import axios from '@/common/js/request'

export function allroledataList(params){
    const allroledataList = axios({
        url: '/role/selectRolePageList',
        method: 'get',
        params
    })
    return allroledataList
}
export function selectRoleList(params){
    const selectRoleList = axios({
        url: '/role/selectRoleList',
        method: 'get',
        params
    })
    return selectRoleList
}
export function allmenuTree(params){
    const allmenuTree = axios({
        url: '/permission/selectPermissionTreeList',
        method: 'get',
        params
    })
    return allmenuTree
}
export function selectRoleMaxSort(params){
    const selectRoleMaxSort = axios({
        url: '/role/selectRoleMaxSort',
        method: 'get',
        params
    })
    return selectRoleMaxSort
}
export function delRole(params){
    const delRole = axios({
        url: '/role/deleteRole',
        method: 'get',
        params
    })
    return delRole
}
export function saveRole(data){
    const saveRole = axios({
        url: '/role/saveRole',
        method: 'post',
        data
    })
    return saveRole
}
export function updateRole(data){
    const updateRole = axios({
        url: '/role/updateRole',
        method: 'post',
        data
    })
    return updateRole
}